import React, { useEffect, useState } from "react";
import Table from 'rc-table';
import { Navbar, Nav, NavDropdown, Container, Button, Modal} from 'react-bootstrap';
import FormRow from "../Components/FormRow/FormRow";
import CircleLoader from "../Components/CircleLoader";
import { CSVLink } from "react-csv";
import { useAlert } from 'react-alert'
import {downloadInvoice} from "../Components/PdfDownload";

function Deals({}) {
    const alert = useAlert();
    // const retailerId = "62422003a9661467846cb648";
    const hostname = "https://heed.herokuapp.com";
    // const hostname = "http://localhost:5000";

    // STATES
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [reportsTableData, setReportsTableData] = useState([]);
    const [reportsData, setReportsData] = useState({});
    const [invoicesData, setInvoicesData] = useState([]);

    const [show, setShow] = useState(false);
    const [showLiveDeals, setShowLiveDeals] = useState(true);
    const [showPage, setShowPage] = useState("deals");
    const [reportStart, setReportStart] = useState("");
    const [reportEnd, setReportEnd] = useState("");

    const [userAccount, setUserAccount] = useState("");
    const [admin, setAdmin] = useState(false);
    const [pos, setPos] = useState(true);
    const [branding, setBranding] = useState(null);

    const [share, setShare] = useState(1);

    
    const [name, setName] = useState("John Doe");
    const [email, setEmail] = useState("JohnD@gmail.com");
    const [phone, setPhone] = useState("5193159313");
    const [lp, setLP] = useState("Canopy Growth");
    const [brand, setBrand] = useState("WANA");
    const [product, setProduct] = useState("Wana Quick Midnight Berry Indica 2pk");
    const [caseUnits, setCaseUnits] = useState(1);
    const [type, setType] = useState("purchase");
    const [sku, setSKU] = useState("628630000148");
    const [rebate, setRebate] = useState(2);
    const [description, setDescription] = useState("For each pack of Wana Quick Midnight Berry gummies, you will receive $2");
    // const [type, setType] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
        

    // DATA

    useEffect(() => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        let user = localStorage.getItem("@user");
        setUserAccount(user);
        if (!retailerId) {
            return window.location.href = "/";
        }
        if (retailerId === "admin") {
            setAdmin(true);
        } else {
            getInfo();
            setAdmin(false);
        }


        setLoading(true);
        if (showPage == "reports") {
            getReports();
        }
        else {
            getDeals();
        }
    }, [showPage, showLiveDeals]);

    const columns = [
        {
            title: 'LP',
            dataIndex: 'lp',
            key: 'lp',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Product',
            render: row => row.product.name,
            key: 'product',
            ellipsis: true,
            width: 350,
        },
        {
            title: 'Data Request',
            render: row => `${row.rebate.type}`,
            key: 'type',
            width: 100,
        },
        {
            title: 'Data Dollars',
            render: row => `$${(row.rebate.rebate * share).toFixed(2)}`,
            key: 'rebate',
            width: 100,
        },
        {
            title: 'Dates from',
            render: row => new Date(row.rebate.start).toLocaleDateString(),
            key: 'start',
            width: 150,
        },
        {
            title: 'Dates to',
            render: row => new Date(row.rebate.end).toLocaleDateString(),
            key: 'end',
            width: 150,
        }
    ];



    const reportsColumns = [
        {
            title: 'Generated On',
            render: row => new Date(row.generated).toLocaleDateString(),
            key: 'generated',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Report Start',
            render: row => new Date(row.startDate).toLocaleDateString(),
            key: 'generated',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Report End',
            render: row => new Date(row.endDate).toLocaleDateString(),
            key: 'generated',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Retailers',
            render: row => row.retailers,
            key: 'retailers',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Retailers Made',
            render: row => `$${row.earned}`,
            key: 'earned',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Heed Made',
            render: row => `$${row.heed}`,
            key: 'heed',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Download',
            render: row => {
                return (
                    <DownloadReport id={row.reportId}>
                    </DownloadReport> 
                )
            },
            key: 'end',
            width: 150,
        }
    ];


    const invoicesColumns = [
        {
            title: 'Date',
            render: row => new Date(row.generated).toLocaleDateString(),
            key: 'generated',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Period Date Start',
            render: row => new Date(row.startDate).toLocaleDateString(),
            key: 'generated',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Period Date End',
            render: row => new Date(row.endDate).toLocaleDateString(),
            key: 'generated',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Deals Participated In',
            render: row => `${row.deals}`,
            key: 'deals',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Data Dollars',
            render: row => `$${row.earned}`,
            key: 'earned',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Download',
            render: row => {
                return (
                    <button style={{textDecoration:"none", backgroundColor: "#64b591"}}
                    className="text-white hover:opacity-80 text-sm font-medium p-2.5 rounded-md" onClick={() => {
                        let deals = row.dealObjs;
                        // Retailer Data
                        var total = 0;
                        var heedTotal = 0;
                        var invoiceLines = [];
                        var totals = [];
                        for (var deal in deals) {
                            deal = deals[deal];
                            invoiceLines.push([
                               `${deal.product.name} - ${deal.rebate.type} data request`,
                               `$${deal.rebate.rebatePerUnit}`,
                               deal.quantity,
                               `$${deal.earned.toFixed(2)}`,
                               `$${deal.heedEarned.toFixed(2)}`,
                               `$${(parseFloat(deal.earned) + parseFloat(deal.heedEarned)).toFixed(2)}`
                            ]);
                            total += deal.earned;
                            heedTotal += deal.heedEarned;
                        }

                        console.log(row);
                        

                        totals.push(
                            {
                                col1: 'Earned: ',
                                col2: `$${total.toFixed(2)}`,
                                style: {
                                    fontSize: 10 //optional, default 12
                                }
                            }
                        );


                        totals.push(
                            {
                                col1: 'Data Dollars Fee: ',
                                col2: `$${heedTotal.toFixed(2)}`,
                                style: {
                                    fontSize: 10 //optional, default 12
                                }
                            }
                        );

                        totals.push({
                            col1: 'Total:',
                            col2: `$${(parseFloat(total) + parseFloat(heedTotal)).toFixed(2)}`,
                            style: {
                                fontSize: 12 //optional, default 12
                            }
                        });
                        
                        var retailerBranding =  {
                            label: "Invoice issued for:",
                            name: branding.retailer,
                            address: branding.address,
                            phone: branding.phone,
                            email: branding.email,
                            other: branding.website
                        };
                                
                        downloadInvoice(row.index, new Date(row.generated).toLocaleDateString(), retailerBranding, invoiceLines, totals);
                    }}>Download</button>
                )
            },
            key: 'end',
            width: 150,
        }
    ];

    const getReports = () => {

        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");

        if (!token || !retailerId) {
            return window.location.href = "/";
        }

        var url = admin ? `${hostname}/api/buyersClub/deals/reports` : `${hostname}/api/buyersClub/${retailerId}/deals/invoices`;
        fetch(url , {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {

            if (response.success) {
                var reports = response.reports;

                // LOAD ADMIN REPORTS
                if (admin) {
                    var reportsArr = [];
                    if (response.reports) {
                        
                        for (var key of Object.keys(reports)) {
                            var report = reports[key];
                            var earned = 0;
                            var heed = 0;
                            var retailers = [];
                            report.map(entry => {{
                                if (!retailers.includes(entry.retailerId)) {
                                    retailers.push(entry.retailerId);
                                }
                                earned+=parseFloat(entry.earned);
                                heed+=parseFloat(entry.heedEarned);
                            }});
                            let reportObj = {
                                retailers: retailers.length,
                                heed: heed.toFixed(2),
                                earned: earned.toFixed(2),
                                startDate: report[0].startDate,
                                endDate: report[0].endDate,
                                generated: report[0].generated,
                                reportId: key
                            }
    
                            // reportObj.generated = reports[key][0].generated;
                            // reportObj.reportId = reports[key][0].reportId;
                            reportsArr.push(reportObj);
                        }
                    }
    
                    setReportsData(response.reports);
                    setReportsTableData(reportsArr);
                    setTimeout(() => {
                        setLoading(false);
                    }, 150);
                }

                // LOAD RETAILER INVOICES
                else {
                    var invoicesArr = [];
                    if (response.reports) {
                        var count = 1;                        
                        for (var key of Object.keys(reports)) {
                            var report = reports[key];
                            var dealIds = [];
                            var dealObjs = [];
                            var earned = 0;
                            report.map(entry => {{
                                if (!dealIds.includes(entry.deal._id)) {
                                    dealIds.push(entry.deal._id);
                                    entry.deal.earned = entry.earned;
                                    entry.deal.quantity = entry.quantity;
                                    entry.deal.heedEarned = entry.heedEarned;
                                    dealObjs.push(entry.deal);
                                }
                                earned+=parseFloat(entry.earned);
                            }});
                            let invoiceObj = {
                                index: count,
                                deals: dealIds.length,
                                dealObjs: dealObjs,
                                earned: earned.toFixed(2),
                                startDate: report[0].startDate,
                                endDate: report[0].endDate,
                                generated: report[0].generated,
                                reportId: key
                            }
    
                            invoicesArr.push(invoiceObj);
                            count+=1;
                        }
                    }

                    setInvoicesData(invoicesArr)
                    setTimeout(() => {
                        setLoading(false);
                    }, 150);
                }



            } else {
                alert.show(response.error , {
                    timeout: 0,
                    type: 'error'
                });
                setTimeout(() => {
                    localStorage.removeItem("@token");
                    return window.location.href = "/";
                }, 4000);
            }
            

        })
        .catch((err) => {console.log(err);
        });
    }  

    


    const getDeals = () => {

        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");

        if (!token || !retailerId) {
            return window.location.href = "/";
        }

        var url = admin ? `${hostname}/api/buyersClub/deals/${showLiveDeals ? "live" : "expired"}` : `${hostname}/api/buyersClub/${retailerId}/deals/${showLiveDeals ? "live" : "expired"}`;
        fetch(url , {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                var dealsArr = response.data.map(deal => {                    
                    deal.key = deal._id;
                    var totals = 0;
                    var quantity = 0;
                    if (deal.reports) {
                        totals = deal.reports.map(report => {
                            if (report.retailerId == retailerId) {
                                return report.earned;
                            } else {
                                return 0;
                            }
                        }).reduce((a, b) => a + b, 0);
                        quantity = deal.reports.map(report => {
                            if (report.retailerId == retailerId) {
                                return report.quantity;
                            } else {
                                return 0;
                            }
                        }).reduce((a, b) => a + b, 0);

                    }
                    deal.quantity = quantity;
                    deal.dataDollars = totals;
                    return deal;
                });   
                setTableData(dealsArr);
                setTimeout(() => {
                    setLoading(false);
                }, 150);
            } else {
                alert.show(response.error , {
                    timeout: 0,
                    type: 'error'
                });
                setTimeout(() => {
                    localStorage.removeItem("@token");
                    return window.location.href = "/";
                }, 4000);
            }
        })
        .catch((err) => {console.log(err);
        });
    }  


    const deleteDeal = (key) => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/";
        }

        //  body: JSON.stringify({}), 
        fetch( `${hostname}/api/buyersClub/deals/${key}`, {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "DELETE", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                setLoading(true);
                getDeals();
            }  else {
                alert.show(response.error , {
                    timeout: 0,
                    type: 'error'
                });
                setTimeout(() => {
                    localStorage.removeItem("@token");
                    return window.location.href = "/";
                }, 4000);
            }
        })
        .catch((err) => {console.log(err);
        });
    }  

    const generateReport = () => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/";
        }

         //  body: JSON.stringify({}), 
        //  ${hostname}
        // http://localhost:5000
         fetch( `${hostname}/api/buyersClub/deals/report`, {    body: JSON.stringify({startDate: reportStart, endDate: reportEnd}), headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "POST", crossDomain:true })
         .then((response) => response.json())
         .then(async (response) => {
             if (response.success) {
                 setLoading(true);
                 getDeals();
             }  else {
                alert.show(response.error , {
                    timeout: 0,
                    type: 'error'
                });
            }
         })
         .catch((err) => {console.log(err);
         });
    }

    const getInfo = () => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/";
        }


         fetch( `${hostname}/api/buyersClub/${retailerId}/info`, {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
         .then((response) => response.json())
         .then(async (response) => {
             console.log(response);
             if (response.success) {
                console.log(response);
                setShare(response.share);
                setPos(response.connected);
                setBranding(response.branding);
             }  else {
                alert.show(response.error , {
                    timeout: 0,
                    type: 'error'
                });
                setTimeout(() => {
                    localStorage.removeItem("@token");
                    return window.location.href = "/";
                }, 4000);
            }
         })
         .catch((err) => {console.log(err);
         });
    }
    
    const DownloadReport = ({id}) => {
        var report = reportsData[id];
        var downloadArr = [];
        report.map(entry => {
            console.log(entry);
            downloadArr.push({
                generated: new Date(entry.generated).toLocaleDateString(),
                email: entry.email,
                retailer: entry.retailer,
                phone: entry.phone,
                product: entry.deal.product.name,
                upc: entry.deal.product.upc,
                data: entry.deal.rebate.type,
                quantity: entry.quantity,
                total: entry.total,
                earned: entry.earned,
                heed: entry.heedEarned,
                start: new Date(entry.deal.rebate.start).toLocaleDateString(),
                end: new Date(entry.deal.rebate.end).toLocaleDateString()
            })  
        });

        // console.log(deal);
        let headers = [ 
            {label: 'Date Report Generated', key: 'generated'},
            {label: 'Deal Start Date', key: 'start'},
            {label: 'Deal End Date', key: 'end'},
            {label: 'Retailer', key: 'retailer'},
            {label: 'Phone', key: 'phone'},
            {label: 'Email', key: 'email'},
            {label: 'UPC', key: 'upc'},
            {label: 'Product Name', key: 'product'},
            {label: 'Data Request Type', key: 'data'},
            {label: 'Quantity (Sold/Purchased) Per Unit', key: 'quantity'},
            {label: 'Total (Sold/Purchased) Per Unit', key: 'total'},
            {label: 'Retailer Earnings', key: 'earned'},
            {label: 'Heed Earnings', key: 'heed'}
        ]

        return (


            <CSVLink
                style={{textDecoration:"none", backgroundColor: "#64b591"}}
                className="text-white hover:opacity-80 text-sm font-medium p-2.5 rounded-md"
                headers={headers}
                data={downloadArr}
                filename={`reports-${id}.csv`}
                target="_blank"
            >
            Download
          </CSVLink>
        )



    }


    const createDeal = () => {

        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/";
        }


        if (!name || !email || !phone || !lp || !brand || !product || !sku || !rebate || !startDate || !endDate) {
            setError(true);
            setTimeout (() => {
                setError(false);
            }, 5000);
            return;
        }

        var dealForm = {
            name: name,
            email: email,
            phone: phone,
            lp: lp,
            brand: brand,
            product: {
                name: product,
                upc: sku
            },
            rebate: {
                rebate: rebate,
                units: caseUnits,
                type: type,
                description: description,
                start: startDate,
                end: endDate
            }
        }


        fetch( `${hostname}/api/buyersClub/deals`, {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify(dealForm), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                
                setName("");
                setEmail("");
                setPhone("");
                setLP("");
                setBrand("");
                setProduct("");
                setSKU("");
                setRebate(0);
                setDescription("");
                setStartDate("");
                setEndDate("");
                setType("purchase");
                handleClose();
    

                setLoading(true);
                getDeals();
            }  else {
                alert.show(response.error , {
                    timeout: 0,
                    type: 'error'
                });
                setTimeout(() => {
                    localStorage.removeItem("@token");
                    return window.location.href = "/";
                }, 4000);
            }
        })
        .catch((err) => {console.log(err);
        });

    }



    return (
        <div>

            <Navbar className="h-20" bg="light" >
                <Container style={{paddingLeft:40, paddingRight:40}} className="max-w-6xl m-auto" fluid>
                    <Navbar.Brand style={{color: "#64b591", fontWeight:"bold"}}>HBC</Navbar.Brand>
                    <Navbar.Text>
                        Signed in as: <span className="font-bold">{userAccount}</span>
                    </Navbar.Text>

                    <button onClick={() => {
                    localStorage.removeItem("@token");
                    localStorage.removeItem("@retailer");
                    return window.location.href = "/";
                }} className="mt-2 p-2 py-2 rounded-md border border-gray-300">LOGOUT</button>

                </Container>
            </Navbar>
            <div className="p-16 max-w-6xl m-auto"> 


            <div className="mb-5">


                {
                !pos ? (
                    <div style={{minWidth:"100%", marginTop:0, marginBottom:15}} className="helper">
                        <p>PLEASE REACH OUT TO INFO@HEED.CHAT IN ORDER TO CONNECT YOUR POS AND START EARNING DATA DOLLARS!</p>
                    </div>
                ) : null
            }


            </div>

            <h1 className="mb-6 font-bold">Deals</h1>

                <div className="flex row items-center justify-center">

                    <div className="flex justify-center">
                        {
                            showLiveDeals && showPage == "deals" ? (
                                    <button onClick={() => {setShowPage("deals");setShowLiveDeals(true)}} style={{backgroundColor:"#64b591"}} className="text-white hover:opacity-80 font-medium p-2.5 rounded-md mr-3 w-60">Live Deals</button>
                            ) : (
                                <button onClick={() => {setShowPage("deals");setShowLiveDeals(true)}} style={{backgroundColor:"#999999"}} className="text-white hover:opacity-80 font-medium p-2.5 rounded-md mr-3 w-60">Live Deals</button>
                            )
                        }
                        
                        {!showLiveDeals && showPage == "deals" ? (
                            <button onClick={() => {setShowPage("deals");setShowLiveDeals(false)}} style={{backgroundColor: "#64b591"}} className="text-white hover:opacity-80 font-medium p-2.5 w-60 rounded-md ml-3">Past Deals</button>
                        ) : (
                            <button onClick={() => {setShowPage("deals");setShowLiveDeals(false)}} style={{backgroundColor: "#999999"}} className="text-white hover:opacity-80 font-medium p-2.5 w-60 rounded-md ml-3">Past Deals</button>
                        )}
                        
                        {showPage == "reports" ? (
                            <button onClick={() => {setShowPage("reports");}} style={{backgroundColor: "#64b591"}} className="text-white hover:opacity-80 font-medium p-2.5 w-60 rounded-md ml-3">{admin ? "Reports" : "Invoices"}</button>
                        ) : (
                            <button onClick={() => {setShowPage("reports");}} style={{backgroundColor: "#999999"}} className="text-white hover:opacity-80 font-medium p-2.5 w-60 rounded-md ml-3">{admin ? "Reports" : "Invoices"}</button>
                        )}

                        {/* {admin === true ? (
                            <>
                                {showPage == "reports" ? (
                                    <button onClick={() => {setShowPage("reports");}} style={{backgroundColor: "#64b591"}} className="text-white hover:opacity-80 font-medium p-2.5 w-60 rounded-md ml-3">Reports</button>
                                ) : (
                                    <button onClick={() => {setShowPage("reports");}} style={{backgroundColor: "#999999"}} className="text-white hover:opacity-80 font-medium p-2.5 w-60 rounded-md ml-3">Reports</button>
                                )}
                            </>
                        ) : null} */}



                    </div>


                    {admin ? (
                        <div className="mt-4 flex justify-center">
                            
                            <input type="date" className=" p-2.5 w-60 rounded-md border border-gray-300"  onChange={(e) => {setReportStart(e.target.value)}} value={reportStart}></input>
                            <input type="date" className="p-2.5 w-60 rounded-md ml-3 border border-gray-300"  onChange={(e) => {setReportEnd(e.target.value)}} value={reportEnd}></input>


                            <button onClick={() => {
                                generateReport();
                            }} style={{backgroundColor:"#64b591"}} className="text-white hover:opacity-80 font-medium p-2.5 w-60 rounded-md ml-3">Generate Report</button>
                            
                        </div>
                    ) : null}

                    {admin ? (
                        <div className="mt-4 flex justify-center">

                            <button onClick={() => {handleShow()}} style={{width:"100%", maxWidth:760, backgroundColor: "#64b591"}} className="text-white hover:opacity-80 font-medium p-2.5 rounded-md">Create Deal</button>  
                        </div>
                    ) : null}



                    {loading ? (
                        <CircleLoader></CircleLoader>
                    ) : (
                        <>
                            {showPage == "deals" ? (
                                <Table expandable={{
                                    expandRowByClick: true,
                                    expandedRowRender: record => 
                                    {
                                        return (
                                        <div className="mt-1 mb-2.5 flex flex-col">

                                                {
                                                    admin ? (
                                                        <div>
                                                            <h5>Contact</h5>
                                                            <p>{record.name} - {record.email} & {record.phone}</p>
                                                        </div>
                                                    ) : null
                                                }
                                                
                                                <div>
                                                    <h5>UPC Code</h5>
                                                    <p>{record.product.upc}</p>
                                                </div>
                                                <div>
                                                    <h5>Description</h5>
                                                    <p>{record.rebate.description}</p>
                                                </div>


                                                {
                                                    admin && showLiveDeals ? (
                                                    <div>
                                                        <h5>Actions</h5>


                                                        <button className="p-2 py-2 rounded-md border border-gray-500" onClick={() => {
                                                                deleteDeal(record.key);
                                                            }}>
                                                                Delete
                                                        </button>
                                                    </div>
                                                
                                                    ) : null
                                                }

                                            

                                            

                                        </div>
                                        )
                                    }
                                }} className="mt-5" columns={columns} data={tableData} />
                            ) : (
                                <>
                                {admin === true ? (
                                    <Table className="mt-5" columns={reportsColumns} data={reportsTableData} />
                                ) : (
                                    <Table className="mt-5" columns={invoicesColumns} data={invoicesData} />
                                )}
                                </>
                            )}
                        </>

                    )}
                    


                    <Modal className="Create-user-modal" dialogClassName="modal-90w" fullscreen={"sm-down"} show={show} onHide={handleClose} animation={true}>
                        <Modal.Header closeButton>
                        <Modal.Title>Input Deal Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>


                        
                        <FormRow label="LP Contact Name" placeholder="John D" value={name} setValue={setName}></FormRow>
                        <FormRow label="LP Contact Email" placeholder="JohnD@gmail.com" value={email} setValue={setEmail}></FormRow>
                        <FormRow label="LP Contact Phone" placeholder="519 313 9512" value={phone} setValue={setPhone}></FormRow>

                        <FormRow label="LP" placeholder="Aphria" value={lp} setValue={setLP}></FormRow>
                        <FormRow label="Brand" placeholder="GOOD SUPPLY" value={brand} setValue={setBrand}></FormRow>
                        
                        <FormRow label="Product" placeholder="Grower's Choice Sativa 28G" value={product} setValue={setProduct}></FormRow>
                        <FormRow label="UPC" placeholder="0694144007935" value={sku} setValue={setSKU}></FormRow>


                        {/* <FormRow label="Price" placeholder="$99.96" value={price} setValue={setPrice}></FormRow> */}
                        
                        <div className="px-4" style={{marginTop:15, marginBottom:30}}>
                            <label style={{marginBottom:15}}>Data Request</label>
                            <select className="p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}}
                                onChange={(e) => {setType(e.target.value)}} 
                                value={type}
                            >
                                <option value="purchase">Purchase</option>
                                <option value="sale">Sale</option>
                            </select>
                        </div>

                        {
                            type === "purchase" ? (
                                <div className="px-4" style={{marginTop:15, marginBottom:30}}>
                                    <label style={{marginBottom:15}}>Units / Case</label>
                                    <input min="1" type="number" className="p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} placeholder={"12"} onChange={(e) => {setCaseUnits(e.target.value)}} value={caseUnits}></input>
                            </div>
                            
                            ) : null
                        }

                        {/* <FormRow label="Rebate" placeholder="$10" value={rebate} setValue={setRebate}></FormRow> */}
                        <div className="px-4" style={{marginTop:15, marginBottom:30}}>
                            <label style={{marginBottom:15}}>Data Dollars {type === "purchase"? "/ Case" : ""}</label>
                            <input min="0.1" type="number" className="p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} placeholder={"$10"} onChange={(e) => {setRebate(e.target.value)}} value={rebate}></input>
                        </div>
                        
                        {/* <FormRow label="Description" placeholder="For each Grow'ers Choice Sativa (SKU x) purchased you will get 10 data dollars" value={description} setValue={setDescription}></FormRow> */}
                        <div className="px-4" style={{marginTop:15, marginBottom:30}}>
                            <label style={{marginBottom:15}}>Description</label>

                            <textarea className="p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} 
                                placeholder="For each Grow'ers Choice Sativa (SKU x) purchased you will get 10 data dollars"
                                onChange={(e) => {setDescription(e.target.value)}} 
                                value={description}
                            >
                            </textarea>
                        </div>


                        {/* <FormRow label="Date start" placeholder="5/30/2022" value={startDate} setValue={setStartDate}></FormRow> */}
                        <div className="px-4" style={{marginTop:15, marginBottom:30}}>
                            <label style={{marginBottom:15}}>Data Start Date</label>
                            <input type="date" className="p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} onChange={(e) => {setStartDate(e.target.value)}} value={startDate}></input>
                        </div>
                        
                        {/* <FormRow label="Date end" placeholder="5/30/2023" value={endDate} setValue={setEndDate}></FormRow> */}
                        <div className="px-4" style={{marginTop:15, marginBottom:30}}>
                            <label style={{marginBottom:15}}>Data End Date</label>
                            <input type="date" className="p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} onChange={(e) => {setEndDate(e.target.value)}} value={endDate}></input>
                        </div>

                        

                        {error ? (
                            <div className="px-4" style={{marginTop:15, marginBottom:30}}>
                                <div className="px-4 py-3 bg-red-400 w-100 h-16 rounded">
                                    <p className="text-white text-md">Please enter all fields!</p>
                                </div>
                            </div>
                        ): null}
                        
                    

                        </Modal.Body>
                        <Modal.Footer>

                        

                            <Button onClick={() => {createDeal()}} style={{backgroundColor: "#64b591", borderColor: "#64b591"}}>
                                Create Deal
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>

        </div>
 
    )
}

export default Deals;
