
function FormRow({label, placeholder, value, setValue}) {

    return (
        <div className="px-4" style={{marginTop:15, marginBottom:30}}>
            <label style={{marginBottom:15}}>{label}</label>
            <input className="p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} placeholder={placeholder} onChange={(e) => {setValue(e.target.value)}} value={value}></input>
        </div>
    )
}


export default FormRow;